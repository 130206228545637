import request from '@/utils/request'

// 查询设备信息分页
export function pageDevice(query) {
  return request({
    url: '/tl/tl-device/page',
    method: 'get',
    params: query
  })
}

// 查询设备信息详细
export function getDevice(data) {
  return request({
    url: '/tl/tl-device/detail',
    method: 'get',
    params: data
  })
}

// 新增设备信息
export function addDevice(data) {
  return request({
    url: '/tl/tl-device/add',
    method: 'post',
    data: data
  })
}

// 修改设备信息
export function updateDevice(data) {
  return request({
    url: '/tl/tl-device/edit',
    method: 'post',
    data: data
  })
}

// 删除设备信息
export function delDevice(data) {
  return request({
    url: '/tl/tl-device/delete',
    method: 'post',
    data: data
  })
}

// 设备信息 导入
export function importData(data) {
  return request({
    url: '/tl/tl-device/import',
    method: 'post',
    data
  })
}
