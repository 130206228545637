<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="设备编码" prop="code">
                <a-input v-model="queryParam.code" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="公司名称" prop="corporateName">
                <a-input v-model="queryParam.corporateName" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="姓名" prop="fullName">
                <a-input v-model="queryParam.fullName" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="生产流水号" prop="serialNumber">
                <a-input v-model="queryParam.serialNumber" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <!-- <a-col :md="!advanced && 6 || 24" :sm="24"> -->
            <a-col :md="6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <!-- <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a> -->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 上传文件 -->
      <import-excel ref="importExcel" @ok="getList" />
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tl:device:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['tl:device:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['tl:device:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="$refs.importExcel.importExcelHandleOpen()" v-hasPermi="['tl:device:import']">
          <a-icon type="import" />导入
        </a-button>

        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="pic" slot-scope="text, record">
          <img style="width:50px;heigth:50px" :src="record.imgUrl" />
        </span>
        <span slot="produceDate" slot-scope="text, record">
          {{ parseTime(record.produceDate,'{y}-{m}-{d}') }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$router.push({path:'/messageAndPush/push',query: {did:record.id}})" v-hasPermi="['tl:pushrecord:list']">
            <a-icon type="edit" />推送记录
          </a>
          <a-divider type="vertical" v-hasPermi="['tl:device:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tl:device:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tl:device:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tl:device:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"

        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageDevice, delDevice } from '@/api/valve/device'
import { listDeviceType } from '@/api/valve/deviceType'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import ImportExcel from './modules/ImportExcel'

export default {
  name: 'Device',
  components: {
    CreateForm, ImportExcel
  },
  mixins: [tableMixin],
  data () {
    return {
      deviceTypeList: [],
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        corporateName: undefined,
        code: undefined,
        fullName: undefined,
        serialNumber: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '设备编码',
          dataIndex: 'code',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '型号名称',
          dataIndex: 'deviceModelName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '公司名称',
          dataIndex: 'corporateName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '姓名',
          dataIndex: 'fullName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '生产流水号',
          dataIndex: 'serialNumber',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '生产日期',
          dataIndex: 'produceDate',
          scopedSlots: { customRender: 'produceDate' },
          width: 100,
          ellipsis: true,
          align: 'center'
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          width: 150,
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 150,
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    listDeviceType().then(res => {
      this.deviceTypeList = res.data
    })
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询设备信息列表 */
    getList () {
      this.loading = true
     pageDevice(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        corporateName: undefined,
        code: undefined,
        fullName: undefined,
        serialNumber: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delDevice(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('valve/device/export', {
            ...that.queryParam
          }, `设备信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
@/api/valve/deviceType
