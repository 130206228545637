<template>
  <a-modal
    :title="title"
    :visible="open"
    :confirm-loading="uploading"
    @cancel="importExcelHandleCancel"
    @ok="importExcelHandleChange"
  >
    <a-spin tip="上传中..." :spinning="uploading">
      <a-form-model ref="form">
        <a-row :gutter="48">
          <a-col :md="16">
            <a-form-model-item label="设备型号">
              <a-select v-model="deviceModelId" placeholder="请选择" style="width: 200px">
                <a-select-option v-for="(item,key) in deviceTypeList" :key="key" :value="item.id">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <a-upload-dragger
        name="file"
        accept=".xlsx, .xls"
        :file-list="fileList"
        :multiple="false"
        :remove="handleRemove"
        :before-upload="beforeUpload"
      >
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">
          请将文件拖拽到此处上传❥(^_-)
        </p>
        <p class="ant-upload-hint">
          支持单个上传，也可以点击后选择文件上传
        </p>
      </a-upload-dragger>
      <p v-hasPermi="['tl:device:download']">导入 <a @click="importTemplate()">模版</a> 下载</p>
    </a-spin>
  </a-modal>
</template>

<script>

import { importData } from '@/api/valve/device'
import { listDeviceType } from '@/api/valve/deviceType'

export default {
  name: 'ImportExcel',
  props: {
  },
  components: {
  },
  data () {
    return {
      title: '数据导入',
      open: false,
      uploadStatus: '',
      fileList: [],
      // 是否禁用上传
      uploading: false,
      updateSupport: 0,
      deviceModelId: undefined,
      deviceTypeList: []
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 导入excel窗体关闭 */
    importExcelHandleCancel (e) {
      this.open = false
      this.fileList = []
      // 关闭后刷新列表
      this.$emit('ok')
    },
    /** 下载模板操作 */
    importTemplate () {
      this.download('tl/tl-device/download', {}, `设备导入模版_${new Date().getTime()}.xls`)
    },
    /** 导入excel窗体开启 */
    importExcelHandleOpen (e) {
      this.open = true
      listDeviceType().then(res => {
        this.deviceTypeList = res.data
      })
    },
    beforeUpload (file) {
      this.fileList = [file]
      return false
    },
    /** 导入excel */
    importExcelHandleChange () {
      if (!this.deviceModelId) {
        this.$message.warning(
          '请选择设备型号',
          3
        )
        return false
      }
      this.uploading = true
      const { fileList } = this
      const formData = new FormData()
      formData.append('file', fileList[0])
      formData.append('deviceModelId', this.deviceModelId)
      importData(formData).then(response => {
        if (response.code === '20001' || response.code === 20001) {
          this.fileList = []
          this.$message.success(`${response.message} ，导入${response.data}条数据。`)
          this.open = false
          this.$emit('ok')
        } else {
          this.$message.error(response.message)
        }
      }).finally(() => {
        this.uploading = false
      })
    },
    handleCheckedUpdateSupport () {
      this.updateSupport = this.updateSupport === 0 ? 1 : 0
    },
    handleRemove () {
      this.fileList = []
      this.uploading = false
    }
  }
}
</script>
