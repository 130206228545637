<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="设备编码" prop="code">
        <a-input v-model="form.code" placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item label="生产日期" prop="produceDate">
        <a-date-picker @change="onChange" placeholder="请选择"/>
      </a-form-model-item>
      <a-form-model-item label="生产流水号" prop="serialNumber">
        <a-input v-model="form.serialNumber" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="设备型号" prop="deviceModelId">
        <a-select v-model="form.deviceModelId" placeholder="请选择" style="width: 200px">
          <a-select-option v-for="(item,key) in deviceTypeList" :key="key" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getDevice, addDevice, updateDevice } from '@/api/valve/device'
import { listDeviceType } from '@/api/valve/deviceType'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data() {
    return {
      previewVisible: false,
      fileList: [
      ],
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: 0,
        code: '',
        deviceModelId: undefined,
        produceDate: '',
        serialNumber: ''
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        valveName: [
          { required: true, message: '设备名称不能为空', trigger: 'blur' }
        ],
        valveMac: [
          { required: true, message: '设备MAC地址不能为空', trigger: 'blur' }
        ]
      },
      deviceTypeList: []
    }
  },
  filters: {
  },
  created() {
    listDeviceType().then(res => {
      this.deviceTypeList = res.data
    })
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    onChange(e, data) {
      console.log(e, data)
      this.form.produceDate = data
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: 0,
        code: '',
        deviceModelId: undefined,
        produceDate: '',
        serialNumber: ''
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getDevice({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id) {
            updateDevice(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addDevice(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .ant-upload-preview {
    position: relative;
    width: 100px;
    height: 100px;
    .upload-icon {
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 1.4rem;
      padding: 0.5rem;
      background: rgba(222, 221, 221, 0.7);
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .mask {
      position: absolute;
      background: rgba(0,0,0,0.4);
      cursor: pointer;
      transition: opacity 0.4s;
      &:hover {
        opacity: 1;
      }
      i {
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1rem;
        margin-top: -1rem;
        color: #d6d6d6;
      }
    }
    img, .mask {
      width: 100%;
      max-width: 180px;
      height: 100%;
      overflow: hidden;
      object-fit: contain;
    }
  }
</style>
@/api/valve/deviceType
