import request from '@/utils/request'

// 查询型号信息列表
export function listDeviceType(query) {
  return request({
    url: '/tl/tl-device-model/list',
    method: 'get',
    params: query
  })
}

// 查询型号信息分页
export function pageDeviceType(query) {
  return request({
    url: '/tl/tl-device-model/page',
    method: 'get',
    params: query
  })
}

// 查询型号信息详细
export function getDeviceType(data) {
  return request({
    url: '/tl/tl-device-model/detail',
    method: 'get',
    params: data
  })
}

// 新增型号信息
export function addDeviceType(data) {
  return request({
    url: '/tl/tl-device-model/add',
    method: 'post',
    data: data
  })
}

// 修改型号信息
export function updateDeviceType(data) {
  return request({
    url: '/tl/tl-device-model/edit',
    method: 'post',
    data: data
  })
}

// 删除型号信息
export function delDeviceType(data) {
  return request({
    url: '/tl/tl-device-model/delete',
    method: 'post',
    data: data
  })
}
